import React from "react";
import ReactDOM from "react-dom/client";
import Home from "./routes/Home.tsx";
import "./index.scss";
import { AuthProvider, AuthProviderProps } from "react-oidc-context";
import { WebStorageStateStore } from "oidc-client-ts";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { Signup } from "./routes/Signup.tsx";
import { Login } from "./routes/Login.tsx";
import { AdminSignup } from "./routes/Admin.tsx";

const oidcConfig: AuthProviderProps = {
  authority: import.meta.env.VITE_APP_OIDC_AUTHORITY ?? "",
  client_id: import.meta.env.VITE_APP_OIDC_CLIENT_ID ?? "",
  redirect_uri: import.meta.env.VITE_APP_OIDC_REDIRECT_URI ?? "",
  loadUserInfo: true,
  scope: import.meta.env.VITE_APP_OIDC_SCOPE ?? "",
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  automaticSilentRenew: true,
  onSigninCallback: () => {
    window.history.replaceState({}, document.title, window.location.pathname);
  },
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/signup/shiny-squeaky-rubberfurs-social",
    element: <Signup />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/admin/jake/rubber",
    element: <AdminSignup />
  }
]);

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <AuthProvider {...oidcConfig}>
      <RouterProvider router={router} />
    </AuthProvider>
  </React.StrictMode>,
);
