/// <reference types="vite-plugin-svgr/client" />
import Respect from "../assets/01_RespectCivility.svg?react";
import AdmissionConduct from "../assets/02_AdmissionConduct.svg?react";
import ClothingProperty from "../assets/03_ClothingProperty.svg?react";
import SafetySecurity from "../assets/04_SafetySecurity.svg?react";
import HealthConcerns from "../assets/05_HealthConcerns.svg?react";
import VenueRespect from "../assets/06_VenueRespect.svg?react";
import PhotographyPrivacy from "../assets/07_PhotographyPrivacy.svg?react";
import FinalAgreement from "../assets/08_FinalAgreement.svg?react";
import HaveFun from "../assets/HaveFun.svg?react";
import SectionCard from "../components/SectionCard";
import { useEffect, useState } from "react";
import classNames from "classnames";
import { useAuth, withAuthenticationRequired } from "react-oidc-context";
import { useNavigate } from "react-router";

export const AdminSignup = withAuthenticationRequired(
  () => {
    const [step, setStep] = useState(0);
    const auth = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
      if (auth.isLoading) return;
      (async () => {
        // Check if user has attendee-2024 role
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (!(auth.user?.profile as any).realm_access.roles.includes("itmaster")) {
          // Not an attendee
          navigate("/");
        }
      })();
    }, [auth.isLoading]);
    return (
      <>
        {step === 0 && (
          <>
            <div className="min-h-svh py-8 lg:py-16">
              <div className="container mx-auto max-w-6xl p-4 xl:px-0">
                <div className="mb-10">
                  <div className="text-4xl font-extrabold text-white">Badge Number</div>
                  {/* Input with button next to it to search */}
                  <div className="mt-4">
                    <input
                      type="text"
                      placeholder="Enter your badge number"
                      className="w-full rounded border border-brok-purple-500 bg-white px-4 py-2"
                    />
                    <div className="mt-2 flex justify-end">
                      <button
                        className="rounded border border-brok-purple-500 bg-white px-4 py-2 font-semibold text-brok-purple-600 hover:border-transparent hover:bg-brok-purple-600 hover:text-white"
                        onClick={() => {
                          setStep(1);
                        }}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {step === 1 && (
          <CodeOfConduct
            onAgreed={() => {
              setStep(2);
              // fetch("https://api.confuzzled.org.uk/v1/events/register", {
              //   method: "POST",
              //   headers: {
              //     Authorization: `Bearer ${auth.user?.access_token}`,
              //     "Content-Type": "application/json",
              //   },
              //   body: JSON.stringify({ eventid: "rubbersocial24" }),
              // })
              //   .then((res) => {
              //     if (res.ok) {
              //       setStep(1);
              //     } else {
              //       alert(
              //         "Failed to signup for the event. Please try again later. If this issue persists, please contact infra@confuzzled.org.uk",
              //       );
              //     }
              //   })
              //   .catch((e) => {
              //     alert(
              //       "Failed to signup for the event. Please try again later. If this issue persists, please contact infra@confuzzled.org.uk",
              //     );
              //     console.error(e);
              //   });
            }}
          />
        )}
        {step === 2 && <Agreed onDone={() => setStep(0)} />}
      </>
    );
  },
  { signinRedirectArgs: { prompt: "login" } },
);

function Agreed({ onDone }: { onDone: () => void }){
  return (
    <div className="min-h-svh py-8 lg:py-16">
      <div className="container mx-auto max-w-6xl p-4 text-center xl:px-0">
        {/* Cheers mate */}
        <div className="thanks mx-auto mb-10 max-w-sm">
          <HaveFun />
        </div>
        <div className="text-4xl font-black italic text-white">Thanks for Signing Up!</div>
        <p className="mt-2 text-lg text-white">
          You've successfully signed up in advance for Shiny, Squeaky & Rubberfurs: The Social!
        </p>
        <p className="mt-2 text-lg text-white">Please hand this back now.</p>

        <div className="container mx-auto p-8 xl:px-0 ">
          <div className="flex justify-center">
            <button
              className="rounded border border-brok-purple-500 bg-white px-10 py-4 font-semibold text-brok-purple-600 hover:border-transparent hover:bg-brok-purple-600 hover:text-white"
              onClick={() => {
                onDone();
              }}
            >
              Done
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
interface COCProps {
  onAgreed: () => void;
}

function CodeOfConduct({ onAgreed }: COCProps) {
  const [agreed, setAgreed] = useState(false);
  return (
    <div className="min-h-svh py-8 lg:py-16">
      <div className="container mx-auto max-w-6xl p-4 xl:px-0">
        <div className="mb-10">
          <div className="text-4xl font-extrabold text-white">Sign Up for Animalz@ConFuzzled</div>
          <p className="mt-2 text-lg text-white">
            In order to attend Animalz@ConFuzzled you will need to agree to the terms set out in the following
            supplimentary Code of Conduct.
          </p>
        </div>
        <SectionCard
          title="Please take the time to read the full rules but in short:"
          icon={<HaveFun className="w-full" />}
        >
          <ul className="list-spaced list-disc">
            <li>Genitals, butts, and nipples covered.</li>
            <li>
              If changing in your room before coming down, cover up as you cross the lobby to get to the nightclub.
            </li>
            <li>Cover up anytime that you leave (even to go to the toilet).</li>
            <li>Cloakroom & changing facilities will also be available in the club.</li>
            <li>
              There’s a simplified dress code{" "}
              <a
                href="https://confuzzled.org.uk/events/animalz/simplified-dress-code/"
                target="_blank"
                className="cursor-pointer hover:underline"
              >
                HERE
              </a>
              .
            </li>
            <li>NO SEX ALLOWED.</li>
            <li>
              NO PHOTOGRAPHY. LEAVE YOUR PHONE IN YOUR ROOM IF YOU CAN. PHONE CAMERAS WILL BE COVERED WITH STICKERS.
            </li>
          </ul>
        </SectionCard>
        <SectionCard title="Respect &amp; Civility" icon={<Respect className="w-full" />} className="mt-6">
          <ul className="list-spaced list-disc">
            <li>
              All attendees must treat each other, event staff, and venue staff respectfully and civilly. This includes
              Club Animalz staff, ConFuzzled staff, and Hilton staff equally. We do not tolerate physical or verbal
              abuse of staff members, or other attendees at the event.
            </li>
            <li>
              Instructions from ConFuzzled UK Ltd. executives, event staff, Hilton Security, and venue staff must be
              followed promptly, primarily for safety reasons.
            </li>
            <li>
              Discrimination of any kind, including but not limited to racism, transphobia, biphobia, misogyny, ageism,
              ableism and body shaming, will not be tolerated. The event is open to everyone and while wearing the
              event-specific gear is encouraged, it is by no means obligatory to enter the event — fursuits or regular
              clothes are also welcome. Event-specific wear includes but is not limited to:
              <ul className="list-normal list-disc pl-8">
                <li>Puppy tails for show - non-insertable</li>
                <li>Petsuits</li>
                <li>Pup Hoods</li>
                <li>Harnesses </li>
                <li>Latex/rubber clothing</li>
                <li>Leather outfits</li>
                <li>Wetsuits</li>
                <li>
                  This list is non-exhaustive, and the rules related to covering up private areas and nipples remain in
                  place. Where in doubt about an outfit, please ask a member of staff. The event retains the right to
                  refuse any outfit it deems inappropriate for the dance floor. If there exists disagreement on what is
                  and is not appropriate, the Director and Associate Director on call will hold the final decision.
                </li>
              </ul>
            </li>
            <li>
              Sexual acts are strictly prohibited within the event space. All actions between attendees must be
              consensual, including but not limited to social interactions, dancing, hugging etc. "No" means no!
            </li>
            <li>
              Attendees are reminded to consume alcohol responsibly. Excessive drinking is discouraged, and individuals
              exhibiting disruptive or intoxicated behaviour may be removed from the event to ensure the safety and
              comfort of all participants.
            </li>
            <li>
              Water stations will be present at the venue, but if you wish to head to the bar for other drinks you will
              need to cover up in clothes suitable for the public areas of the hotel as stipulated in the dress code
              before leaving the nightclub or request a friend to bring your drinks to you.
            </li>
            <li>The medical department may be called where there is a concern for the person's safety.</li>
          </ul>
        </SectionCard>
        <SectionCard title="Admission &amp; Conduct" icon={<AdmissionConduct className="w-full" />} className="mt-6">
          <ul className="list-spaced list-disc">
            <li>
              Entry is managed by the Steward Department, ensuring compliance with outfit policy and ticket
              verification. Only ConFuzzled badge holders are allowed to own an entry ticket to the Club Animalz night.
              Both badge and entry ticket must be shown at the door to the event.
            </li>
            <li>
              Event-specific gear must not be worn openly/visibly in public spaces outside the event venue. It should be
              covered up or carried until attendees can change in the cloakroom.
            </li>
            <li>
              A changing area and privacy tents will be provided for you to change into gear brought to the event.
            </li>
            <li>Attire at the event should cover up any and all genitalia, as well as nipples.</li>
            <li>
              When donning latex-based gear, the use of talc powder is prohibited due to the aerosolisation of latex
              allergens.
            </li>
            <li>
              Sexual acts are strictly prohibited, with consequences ranging from warnings to bans pending the severity
              of the issue. This includes the exposing of genitalia and nipples.
            </li>
            <li>
              Attendees agree to this Code of Conduct and the general ConFuzzled Code of Conduct by participating.
            </li>
          </ul>
        </SectionCard>
        <SectionCard title="Cloakroom &amp; Property" icon={<ClothingProperty className="w-full" />} className="mt-6">
          <ul className="list-spaced list-disc">
            <li>
              Attendees are responsible for their possessions. Confuzzled UK Ltd. is not liable for damage or loss of
              property. We recommend patrons to use the cloakroom for added safety.
            </li>
            <li>
              The cloakroom is available for use at the attendee’s own risk. Again, ConFuzzled UK Ltd. and the venue are
              not liable for loss or damage.
            </li>
            <li>
              The cloakroom is not appropriate for personal belongings that require cold storage such as cooldown vests,
              or medication that has to be stored at a certain temperature. No food/drink, dangerous or illegal items
              will be permitted for storage.
            </li>
          </ul>
        </SectionCard>
        <SectionCard title="Safety &amp; Security" icon={<SafetySecurity className="w-full" />} className="mt-6">
          <ul className="list-spaced list-disc">
            <li>
              Attendees must comply with security measures, and where necessary this includes bag checks by Hilton
              Security.
            </li>
            <li>
              Drug use or possession will result in immediate expulsion and police notification. We adhere strictly to
              venue-specific rules, licence requirements, and English Law.
            </li>
            <li>
              ConFuzzled UK Ltd. Directors & Executives will be present at all times to enforce event rules, alongside
              stewards at doors and cloakroom areas. Hilton Security will assist in rule enforcement.
            </li>
            <li>
              You will not be allowed to bring any restricted items into the event space. This includes weapons,
              liquids, needles, syringes, drugs, and powders. This list is non-exhaustive. If unsure, again, reach out
              for clarification before the event.
            </li>
            <li>
              Only emergency life-saving medication is permissible at the event, however, this should not require cold
              storage (please reach out to medical@confuzzled.org.uk before the event).
            </li>
            <li>Incidents can be reported to any staff member, who will escalate this to the relevant department.</li>
          </ul>
        </SectionCard>
        <SectionCard title="Health Concerns" icon={<HealthConcerns className="w-full" />} className="mt-6">
          <ul className="list-spaced list-disc">
            <li>
              In the event of a medical emergency, please make any staff member aware. They will escalate this to the
              medical department.
            </li>
            <li>
              If you have any private medical concerns and are planning to attend the event, including e.g., epilepsy,
              severe asthma, etc. Please contact the medical department (
              <a className="cursor-pointer hover:underline" href="mailto:medical@confuzzled.org.uk">
                medical@confuzzled.org.uk
              </a>
              ) with any concerns you may have.
            </li>
            <li>
              The event will have medical personnel present that can be called upon in the event of an emergency. There
              will always be personnel on the door that you can approach.
            </li>
            <li>
              Hand sanitising stations will be made available for general hygiene purposes. Additionally, face masks can
              be provided where requested. These will be standard face masks and are provided as a courtesy. If you have
              any medical concerns, please source your own FFP2/FFP3 masks, shields, and any protective equipment that
              would be suitable for your personal medical requirements.
            </li>
          </ul>
        </SectionCard>
        <SectionCard title="Venue Respect" icon={<VenueRespect className="w-full" />} className="mt-6">
          <ul className="list-spaced list-disc">
            <li>
              Attendees are responsible for any damages caused to the venue and must avoid actions that could lead to
              damage. Where damage is noted, we reserve the right to ask you to repair or replace items damaged - and
              you agree to do this within 30 days of the claim.
            </li>
            <li>
              You should under no circumstances enter the backstage areas, or touch/interact with the camera and
              technical gear present.
            </li>
            <li>Cooperation with event and venue staff is essential for a safe and enjoyable event.</li>
          </ul>
        </SectionCard>
        <SectionCard
          title="Photography &amp; Privacy"
          icon={<PhotographyPrivacy className="w-full" />}
          className="mt-6"
        >
          <ul className="list-spaced list-disc">
            <li>
              No photography may take place in the event space. You will be asked to cover up your phone camera lenses
              with stickers provided by the event. The use of cameras will result in a warning, the request to see the
              photo deleted, and expulsion from the event. If possible, we recommend you leave your phone in your room.
            </li>
            <li>Convention cameras will be inactive, and the photography department will not be present.</li>
          </ul>
        </SectionCard>
        <SectionCard title="Final Agreement" icon={<FinalAgreement className="w-full" />} className="mt-6">
          <ul className="list-spaced list-disc">
            <li>
              By attending, participants agree to adhere to these rules alongside the ConFuzzled Code of Conduct,
              fostering a respectful and enjoyable environment for all.
            </li>
          </ul>
        </SectionCard>

        <div className="mt-8 flex items-center justify-center">
          <input
            id="link-checkbox"
            type="checkbox"
            checked={agreed}
            onChange={() => setAgreed(!agreed)}
            className="h-4 w-4 rounded border-gray-100 bg-white text-blue-600 "
          />
          <label htmlFor="link-checkbox" className="ms-2 text-sm font-medium text-white">
            I agree to adhere to the above terms alongside the ConFuzzled{" "}
            <a
              href="https://confuzzled.org.uk/attending/code-of-conduct/"
              target="_blank"
              className="text-blue-600 hover:underline "
            >
              Code of Conduct
            </a>
            .
          </label>
        </div>
        <div className="mt-4 flex justify-center">
          <button
            disabled={!agreed}
            className={classNames(
              agreed ? "hover:cursor-pointer" : "hover:cursor-not-allowed",
              "rounded border border-brok-purple-500 bg-white px-4 py-2 font-semibold text-brok-purple-600 transition-transform duration-300 ease-in-out hover:-translate-y-1 hover:border-transparent hover:bg-brok-purple-600 hover:text-white",
            )}
            onClick={() => {
              if (!agreed) {
                alert("Please agree to the terms before continuing.");
                return;
              }
              onAgreed();
            }}
          >
            Signup
          </button>
        </div>
      </div>
    </div>
  );
}
