/// <reference types="vite-plugin-svgr/client" />
import { useAuth, withAuthenticationRequired } from "react-oidc-context";
import Respect from "../assets/01_RespectCivility.svg?react";
import AdmissionConduct from "../assets/02_AdmissionConduct.svg?react";
import ClothingProperty from "../assets/03_ClothingProperty.svg?react";
import SafetySecurity from "../assets/04_SafetySecurity.svg?react";
import HealthConcerns from "../assets/05_HealthConcerns.svg?react";
import VenueRespect from "../assets/06_VenueRespect.svg?react";
import PhotographyPrivacy from "../assets/07_PhotographyPrivacy.svg?react";
import FinalAgreement from "../assets/08_FinalAgreement.svg?react";
import HaveFun from "../assets/HaveFun.svg?react";
import SectionCard from "../components/SectionCard";
import { useEffect, useState } from "react";
import classNames from "classnames";

export const Signup = withAuthenticationRequired(
  // export const Signup =
  () => {
    const auth = useAuth();
    const [canAuth, setCanAuth] = useState(true);

    useEffect(() => {
      if (auth.isLoading) return;
      (async () => {
        // Check if user has attendee-2024 role
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (!(auth.user?.profile as any).realm_access.roles.includes("attendee-2024")) {
          // Not an attendee
          setCanAuth(false);
        }
      })();
    }, [auth.isLoading]);

    const [step, setStep] = useState(0);
    return (
      <>
        {!canAuth && (
          <div className="min-h-svh py-8 lg:py-16">
            <div className="container mx-auto max-w-6xl p-4 text-center xl:px-0">
              <div className="text-4xl font-black italic text-white">You are not an attendee</div>
              <p className="mt-2 text-lg text-white">
                You need to be an attendee to sign up in advance for Shiny, Squeaky & Rubberfurs: The Social. If you
                believe this is an error, please contact infra@confuzzled.org.uk
              </p>
            </div>
          </div>
        )}
        {canAuth && step === 0 && (
          <CodeOfConduct
            onAgreed={() => {
              fetch("https://api.confuzzled.org.uk/v1/events/register", {
                method: "POST",
                headers: {
                  Authorization: `Bearer ${auth.user?.access_token}`,
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({ eventid: "rubbersocial24" }),
              })
                .then((res) => {
                  if (res.ok) {
                    setStep(1);
                  } else {
                    alert(
                      "Failed to signup for the event. Please try again later. If this issue persists, please contact infra@confuzzled.org.uk",
                    );
                  }
                })
                .catch((e) => {
                  alert(
                    "Failed to signup for the event. Please try again later. If this issue persists, please contact infra@confuzzled.org.uk",
                  );
                  console.error(e);
                });
            }}
          />
        )}
        {canAuth && step === 1 && <Agreed />}
      </>
    );
  },
  { signinRedirectArgs: { prompt: "login" } },
);

function Agreed() {
  return (
    <div className="min-h-svh py-8 lg:py-16">
      <div className="container mx-auto max-w-6xl p-4 text-center xl:px-0">
        {/* Cheers mate */}
        <div className="thanks mx-auto mb-10 max-w-sm">
          <HaveFun />
        </div>
        <div className="text-4xl font-black italic text-white">Thanks for Signing Up in Advance!</div>
        <p className="mt-2 text-lg text-white">
          You've successfully signed up in advance for Shiny, Squeaky & Rubberfurs: The Social! We can't wait to see you
          there.
        </p>
        <p className="mt-2 text-lg text-white">
          Please ensure you have your ConFuzzled badge with you when you arrive at the event.
        </p>
      </div>
    </div>
  );
}
interface COCProps {
  onAgreed: () => void;
}

function CodeOfConduct({ onAgreed }: COCProps) {
  const [agreed, setAgreed] = useState(false);
  return (
    <div className="min-h-svh py-8 lg:py-16">
      <div className="container mx-auto max-w-6xl p-4 xl:px-0">
        <div className="mb-10">
          <div className="text-4xl font-extrabold text-white">
            Sign Up in Advance for Shiny, Squeaky & Rubberfurs: The Social
          </div>
          <p className="mt-2 text-lg text-white">
            In order to attend Shiny, Squeaky & Rubberfurs: The Social you will need to agree to the terms set out in
            the following supplimentary Code of Conduct.
          </p>
        </div>
        <SectionCard
          title="Please take the time to read the full rules but in short:"
          icon={<HaveFun className="w-full" />}
        >
          <ul className="list-spaced list-disc">
            <li>Genitals, butts, and nipples covered.</li>
            <li>
              If changing in your room before coming down, cover up as you cross the lobby to get to the event.
            </li>
            <li>Cover up anytime that you leave (even to go to the toilet).</li>
            <li>Changing area will be available.</li>
            <li>Cloakroom facilities are not available at this event.</li>

            <li>Sexual acts of any kind are prohibited.</li>
            <li>
              Photography by official ConFuzzled photographers only. No personal photography allowed. Phone cameras will be covered with stickers.
            </li>
          </ul>
        </SectionCard>
        <SectionCard title="Respect &amp; Civility" icon={<Respect className="w-full" />} className="mt-6">
          <ul className="list-spaced list-disc">
            <li>
              All attendees must treat each other, event staff, and venue staff respectfully and civilly. This includes
              ConFuzzled staff, and Hilton staff equally. We do not tolerate physical or verbal abuse of staff members,
              or other attendees at the event.
            </li>
            <li>
              Instructions from ConFuzzled UK Ltd. executives, event staff, Hilton Security, and venue staff must be
              followed promptly, primarily for safety reasons.
            </li>
            <li>
              Discrimination of any kind, including but not limited to racism, transphobia, biphobia, misogyny, ageism,
              ableism and body shaming, will not be tolerated. The event is open to all ConFuzzled attendees, and while
              wearing the event-specific gear is encouraged, it is by no means obligatory to enter the event — fursuits
              or regular clothes are also welcome. Event-specific wear includes but is not limited to:
              <ul className="list-normal list-disc pl-8">
                <li>Latex / rubber clothing</li>
                <li>Wetsuits / Neoprene</li>
                <li>Leather outfits</li>
                <li>Petsuits / Zentai / Spandex </li>
                <li>Pup Hoods, Gas Masks, Pet Play Masks</li>
                <li>PVC clothing and accessories</li>
                <li>Harnesses, collars, and non-insertable puppy tails.</li>
                <li>
                  This list is non-exhaustive, and the rules related to covering up private areas and nipples remain in
                  place. When in doubt about an outfit, please ask a member of staff. The event retains the right to
                  refuse any outfit it deems inappropriate for the event. If there exists disagreement on what is and is
                  not appropriate, the Director and Associate Director on duty will hold the final decision.
                </li>
              </ul>
            </li>
            <li>
              As with the Club Animalz night, genitals, nipples (no matter the gender identity) and butts must be fully
              covered. Jockstraps and diapers must <strong>not</strong> be openly worn.
            </li>
            <li>
              Footwear must be worn at all times, and is not optional. This means more than just socks or latex catsuit
              feet - you must have some sort of footwear.
            </li>
            <li>
              For attendees with inflatable latex suits - these must be inflated only within the event space, and
              shouldn't be taken to the panel from a hotel room while inflated. If your costume requires the use of an
              electric inflatable pump, then this must be PAT tested at ConFuzzled beforehand. An announcement will be
              sent with the times and location for PAT testing. If you do not PAT test your electric pump, you will not
              be permitted to use it at the panel.
            </li>
            <li>
              Attendees are reminded to consume alcohol responsibly. Excessive drinking is discouraged, and individuals
              exhibiting disruptive or intoxicated behaviour may be removed from the event to ensure the safety and
              comfort of all participants. A water station will be present, and no alcohol is allowed at the event. The
              medical department may be called where there is a concern for the person's safety.
            </li>
          </ul>
        </SectionCard>
        <SectionCard title="Admission &amp; Conduct" icon={<AdmissionConduct className="w-full" />} className="mt-6">
          <ul className="list-spaced list-disc">
            <li>
              Entry is managed by the Steward Department, ensuring compliance with outfit policy and ticket
              verification. Only ConFuzzled badge holders are allowed to own an entry ticket to the Rubberfurs Social
              event. Both badge and entry ticket must be shown at the door to the event.
            </li>
            <li>
              All rubber and related kink/fetish gear must not be worn visibly in public spaces outside the event room.
              It should be fully covered with regular clothing until you are in the event room.
            </li>
            <li>
              A Changing area and privacy tents will be provided for you to change into gear brought to the event.{" "}
              <br />
              <small>
                <strong>Please note:</strong> Changing spaces are limited, and whenever possible, latex should be put on
                and taken off in your hotel room, with the exception of latex headpieces (and in the case of a medical
                emergency).
              </small>
            </li>
            <li>
              When putting on latex-based gear, either at the panel or in your room, the use of talcum powder or other
              powder based lubricants is prohibited due to the aerosolisation of latex allergens. Latex items must be
              100% free from powders for use at the panel. Latex items contaminated with powder will not be permitted to
              be worn at the event, and you will be asked to leave if you are found to be wearing a contaminated latex
              item.
            </li>
            <li>
              Sexual acts (including heavy petting, inappropriate touching, etc.) are strictly prohibited within the
              event space. Consequences may range from warnings to bans pending the severity of the issue. This includes
              the exposing of genitalia and nipples.
            </li>
          </ul>
        </SectionCard>
        <SectionCard title="Personal Property" icon={<ClothingProperty className="w-full" />} className="mt-6">
          <ul className="list-spaced list-disc">
            <li>
              Attendees are responsible for their possessions. Confuzzled UK Ltd. is not liable for damage or loss of
              property.
            </li>
            <li>
              No cloakroom facilities are available at this panel. Please take this into consideration when bringing
              personal items, electronics, etc.
            </li>
          </ul>
        </SectionCard>
        <SectionCard title="Safety &amp; Security" icon={<SafetySecurity className="w-full" />} className="mt-6">
          <ul className="list-spaced list-disc">
            <li>
              Attendees must comply with security measures, and where necessary this includes bag checks by Hilton
              Security.
            </li>
            <li>
              Drug use or possession will result in immediate expulsion and police notification. We adhere strictly to
              venue-specific rules, licence requirements, and English Law.
            </li>
            <li>
              ConFuzzled UK Ltd. Directors & Executives will be present at all times to enforce event rules, alongside
              stewards at doors and cloakroom areas. Hilton Security will assist in rule enforcement.
            </li>
            <li>
              You will not be allowed to bring any restricted items into the event space. This includes weapons,
              liquids, needles, syringes, drugs, and powders. This list is non-exhaustive. If unsure, again, reach out
              for clarification before the event.
            </li>
            <li>
              Only emergency life-saving medication is permissible at the event, however, this should not require cold
              storage (please reach out to medical@confuzzled.org.uk before the event).
            </li>
            <li>
              Any concerns or incidents can be reported to any staff member, who will escalate this to the relevant
              department.
            </li>
          </ul>
        </SectionCard>
        <SectionCard title="Health Concerns" icon={<HealthConcerns className="w-full" />} className="mt-6">
          <ul className="list-spaced list-disc">
            <li>
              Due to the presence of latex and the risk of latex allergens, we advise those with a latex allergy to not
              attend this panel.
            </li>
            <li>
              In the event of a medical emergency, please make any staff member aware. They will escalate this to the
              medical department. Please understand that if you are wearing gear and suffer a medical emergency, the
              medical team reserves the right to use trauma shears to remove your outfit if deemed required to give you
              appropriate aid.
            </li>
            <li>
              If you have any private medical concerns and are planning to attend the event, latex allergy, severe
              asthma, etc. Please contact the medical department (
              <a className="cursor-pointer hover:underline" href="mailto:medical@confuzzled.org.uk">
                medical@confuzzled.org.uk
              </a>
              ) with any concerns you may have. ConFuzzled does not provide medication, and attendees are expected to
              retain required lifesaving medication on their person at all times.
            </li>
            <li>
              In the event of an emergency, medical personnel can be requested either directly or through another member
              of staff at the event. There will always be a member of ConFuzzled staff stationed at the door, who can be
              approached if the ConFuzzled medical team is needed urgently.
            </li>
          </ul>
        </SectionCard>
        <SectionCard title="Venue Respect" icon={<VenueRespect className="w-full" />} className="mt-6">
          <ul className="list-spaced list-disc">
            <li>
              Attendees are responsible for any damages caused to the venue and must avoid actions that could lead to
              damage. Where damage is noted, we reserve the right to ask you to repair or replace items damaged - and
              you agree to do this within 30 days of the claim.
            </li>
            <li>
              Attendees are reminded to be aware of how their gear may inadvertently cause damage to the hotel
              environment, such as from silicone oil, buckles, etc.
            </li>
            <li>Cooperation with event and venue staff is essential for a safe and enjoyable event.</li>
          </ul>
        </SectionCard>
        <SectionCard
          title="Photography &amp; Privacy"
          icon={<PhotographyPrivacy className="w-full" />}
          className="mt-6"
        >
          <ul className="list-spaced list-disc">
            <li>
              Only photography by official ConFuzzled photography team members will be allowed in the event space. The
              panel will feature a dedicated area for photos with a backdrop setup, and will also feature additional
              ConFuzzled photographers that will roam and take photos during the panel. Please be aware that some of
              these shots may be candid, so by attending, you understand that you may be included in photographs taken
              by the ConFuzzled photography team.
            </li>
            <li>
              If you do not wish to be included in photos taken at the panel, please let the photographers know. We will
              do our best to accommodate this, although we cannot 100% guarantee exclusion due to the nature of the
              panel and how photos will be taken. The only way to guarantee not being photographed at this panel is to
              not attend.
            </li>
            <li>
              Photography by attendees is not permitted at this event. You will be asked to cover up your phone camera
              lenses with stickers provided by the event. The use of cameras will result in a warning, the request to
              see the photo deleted, and expulsion from the event. If possible, we recommend you leave your phone in
              your room.
            </li>
          </ul>
        </SectionCard>
        <SectionCard title="Final Agreement" icon={<FinalAgreement className="w-full" />} className="mt-6">
          <ul className="list-spaced list-disc">
            <li>
              By attending, participants agree to adhere to these rules alongside the ConFuzzled Code of Conduct,
              fostering a respectful and enjoyable environment for all.
            </li>
          </ul>
        </SectionCard>

        <div className="mt-8 flex items-center justify-center">
          <input
            id="link-checkbox"
            type="checkbox"
            checked={agreed}
            onChange={() => setAgreed(!agreed)}
            className="h-4 w-4 rounded border-gray-100 bg-white text-blue-600 "
          />
          <label htmlFor="link-checkbox" className="ms-2 text-sm font-medium text-white">
            I agree to adhere to the above terms alongside the ConFuzzled{" "}
            <a
              href="https://confuzzled.org.uk/attending/code-of-conduct/"
              target="_blank"
              className="text-blue-600 hover:underline "
            >
              Code of Conduct
            </a>
            .
          </label>
        </div>
        <div className="mt-4 flex justify-center">
          <button
            disabled={!agreed}
            className={classNames(
              agreed ? "hover:cursor-pointer" : "hover:cursor-not-allowed",
              "rounded border border-brok-purple-500 bg-white px-4 py-2 font-semibold text-brok-purple-600 transition-transform duration-300 ease-in-out hover:-translate-y-1 hover:border-transparent hover:bg-brok-purple-600 hover:text-white",
            )}
            onClick={() => {
              if (!agreed) {
                alert("Please agree to the terms before continuing.");
                return;
              }
              onAgreed();
            }}
          >
            Signup
          </button>
        </div>
      </div>
    </div>
  );
}
