import { useEffect } from "react";
import { useAuth } from "react-oidc-context";
import { useNavigate } from "react-router";

export const Login = () => {
  const auth = useAuth();
  const navigator = useNavigate();

  useEffect(() => {
    if (!auth.isLoading) {
      if (auth.isAuthenticated) {
        // Skip!
        navigator("/signup/shiny-squeaky-rubberfurs-social");
        return;
      }

      // Finally, it's save to redirect
      auth.signinRedirect({
        prompt: "login",
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  if (auth.isLoading) {
    return <>lalala</>;
  }

  return <div>This shouldn't happen...</div>;
};
