import BrokJuggling from "@/assets/brok-juggling.png";
import { Link } from "react-router-dom";

export default function Hero() {
  return (
    <div className="min-h-fit bg-brok-purple-950">
      <div className="container mx-auto flex flex-wrap p-8 md:p-12 xl:px-0">
        <div className="md:grid md:grid-cols-12 md:gap-6">
          <div className="mx-auto max-w-xl md:col-span-12 md:mt-6 md:w-full md:max-w-none lg:col-span-6">
            <div className="mb-8 md:pr-4 lg:pr-12 xl:pr-16">
              <h3 className="h3 mb-3 text-white text-4xl font-black">ConFuzzled Event Signup</h3>
              <p className="text-xl text-white">
                To take part in any of the following events, you must have signed the supplimentary code of conduct. Do
                that now by clicking on the event you wish to attend.
              </p>
            </div>
            <div className="mb-8 md:mb-0">
              <a
                href="https://animalz.confuzzled.org.uk/signup"
                className="mb-3 flex items-center rounded-lg bg-animalz-pink bg-opacity-50 p-5 text-lg shadow-md transition duration-300 ease-in-out hover:scale-105 hover:shadow-lg"
              >
                <div>
                  <div className="mb-1 font-black italic leading-snug tracking-tight text-white">
                    Animalz@ConFuzzled
                  </div>
                  <div className="text-white">
                    Club Animalz is a fun, carefree, loving club night for pups, furries, and anyone who is a bit of an
                    animal. Whether a fluffy fox, a rubbery dog, leathery gator, or just what you feel comfortable in.
                    So come and be your wild, unashamedly exciting true self.
                  </div>
                </div>
              </a>
              <Link
                className="mb-3 flex items-center rounded-lg bg-black bg-opacity-50 p-5 text-lg shadow-md transition duration-300 ease-in-out hover:scale-105 hover:shadow-lg"
                to="/signup/shiny-squeaky-rubberfurs-social"
              >
                <div>
                  <div className="mb-1 font-black italic leading-snug tracking-tight text-white">
                    Shiny, Squeaky & Rubberfurs: The Social
                  </div>
                  <div className="text-white">
                    Looking to show off your rubber, neoprene or other shiny gear? Want to chat about latex-related
                    escapades? Join us at the Shiny, Squeaky & Rubberfurs Social!
                    <br />
                    <small className="leading-tight">
                      {" "}
                      - If you're wearing gear to this event, it must be fully covered when moving about the hotel.
                      <br /> Warning: Airborne Latex particles will be present.
                    </small>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="mx-auto mb-8 hidden max-w-xl md:order-1 md:col-span-5 md:mb-0 md:w-full md:max-w-none lg:col-span-6 lg:block">
            <div className="h-full transition-all">
              <div className="flex h-full flex-col items-center justify-center">
                <img src={BrokJuggling} className="w-[30rem]" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
